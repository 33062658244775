import moment from 'moment'
import { mapFields } from 'vuex-map-fields'
import {
  BFormGroup,
  BModal,
  BButton,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
  BSpinner,
} from 'bootstrap-vue'
import { mapState, mapGetters } from 'vuex'
import vSelect from 'vue-select'
import DateRangePicker from 'vue2-daterange-picker'
import '@core/scss/vue/libs/vue-select.scss'
import CodeInput from 'vue-verification-code-input'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import PopoverInfo from '@/views/components/popover/PopoverInfo.vue'
import {
  today,
  last7,
  last30,
  firstDateOfMonth,
  lastDateOfMonth,
} from '@/store/helpers'
import PaymentMethods from '@/views/pages/komship/dashboard/modal/PaymentMethod.vue'
import ScanQRIS from '@/views/pages/komship/dashboard/modal/ScanQRIS.vue'
import ModalPaymentExist from '@/views/pages/komship/dashboard/modal/ModalPaymentExist.vue'
import ModalPIN from '@/views/components/modal/ModalPIN.vue'
import ModalMaxChancePIN from '@/views/components/modal/ModalMaxChancePIN.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    BFormSelect,
    BPagination,
    DateRangePicker,
    PopoverInfo,
    BRow,
    BButton,
    CodeInput,
    BSpinner,
    vSelect,
    PaymentMethods,
    ScanQRIS,
    ModalPaymentExist,
    ModalPIN,
    ModalMaxChancePIN,
  },
  data() {
    return {
      picker: {
        startDate: last30,
        endDate: today,
      },
      isVisibility: true,
      locale: {
        format: 'dd/mm/yyyy',
        daysOfWeek: ['Min', 'Sen', 'Sel', 'Rab', 'Kam', 'Jum', 'Sab'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'Mei', 'Jun', 'Jul', 'Agu', 'Sep', 'Okt', 'Nov', 'Des'],
      },
      ranges: {
        '7 Hari Terakhir': [last7, today],
        '30 Hari Terakhir': [last30, today],
        'Bulan Ini': [firstDateOfMonth, today],
      },
      today,
      last7,
      last30,
      firstDateOfMonth,
      lastDateOfMonth,
      user: {},
      tableTitles: [
        'Tanggal',
        'Tujuan Penarikan',
        'Status',
        'Jumlah Penarikan',
        'Rincian',
      ],
      snapToken: '31f5ef26-5121-44e9-97b5-f469039bf6cf',
      modalTitle: null,
      stepNow: 0,
      nominalState: null,
      rekTujuanState: null,
      obj: null,
      status: true,
      visibilityPin: 'number',
      resTarikSaldo: {},

      loadingSubmitTopup: false,
      fieldwidth: '',
      fieldheight: '',

      nominalPossible: false,
      maxWithdraw: 0,
      remainingSaldo: 0,
      withdrawPossibilites: 0,
      nominalReturFinish: 0,
      isCheckSaldo: false,
      idealBalance: 0,
      potencyIncome: 0,
      potencyRetur: 0,
      loadingLoadDataWithdraw: false,
      minWithdraw: false,
      isMaxWithdraw: false,
      maxValueWithdraw: 0,
      loadingSubmitTopUp: false,
      loadingConfirmationPin: false,
      isDisableSubmitWithdraw: false,
      loadingTopUp: false,
      dataBillExist: null,
      qrBillDetail: {},

      isWeekend: false,
      messageTopup: '',
    }
  },
  computed: {
    ...mapFields('saldo', [
      'dateRange',
      'nominalTopUp',
      'selectedRekTujuan',
      'nominal',
      'pin',
    ]),
    ...mapState('saldo', [
      'saldo',
      'saldoPending',
      'table',
      'riwayatPenarikans',
    ]),
    ...mapGetters('saldo', ['rekenings', 'rekening', 'rekTujuanOptions']),
    ...mapState('dashboard', ['profile']),
  },
  watch: {
    'table.currentPage': {
      handler() {
        this.$store.dispatch('saldo/getWithdrawalRequest')
      },
    },
    dateRange: {
      handler() {
        this.$store.commit('saldo/UPDATE_CURRENT_PAGE', 1)
        this.$store.dispatch('saldo/getWithdrawalRequest')
      },
      deep: true,
    },
    nominalTopUp(newValue) {
      const result = newValue.replace(/\D/g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      this.$nextTick(() => {
        this.nominalTopUp = `Rp ${result}`
      })
    },
  },
  beforeMount() {
    this.$store.dispatch('saldo/init')
  },
  methods: {
    formatRibuan(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    formatRupiah(x) {
      return `Rp ${this.formatRibuan(x)}`
    },
    formatRupiahTopup(x) {
      return `${this.formatRibuan(x)}`
    },
    showTopUpModal() {
      this.nominalTopUp = ''
      this.$bvModal.show('modalTopUp')
    },
    async topUpSaldo() {
      this.loadingSubmitTopup = true
      if (this.formatToNumber(this.nominalTopUp) > 500000) {
        this.loadingTopUp = true
        try {
          const response = await this.$store.dispatch('saldo/newTopUpSaldo')
          const { data } = response
          if (!data.status) throw data
          try {
            window.open(data.data.invoice_url, '_blank').focus()
          } catch (e) {
            this.$refs.modalTopUp.hide()
            this.loadingTopUp = false
            this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
          }
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Cek Rincian Saldo',
            confirmButtonClass: 'btn btn-primary',
          }).then(then => {
            if (then.isConfirmed) {
              this.$router.push('/keuangan/saldo/detail')
            }
          })
        } catch (e) {
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        } finally {
          this.$store.commit('saldo/UPDATE_NOMINAL', '')
        }
      } else {
        this.getWeekend()
        this.$bvModal.show('paymentMethod')
      }
    },
    getWeekend() {
      const day = moment().day()
      const month = moment().month() + 1

      const date = moment().format('D')
      const customWeekEnd = ['3', '4', '5', '6', '7', '8', '12', '13', '14', '15', '16']

      if (month === 9 && customWeekEnd.includes(date)) {
        this.isWeekend = customWeekEnd.includes(date)
      } else {
        this.isWeekend = day === 4 || day === 5 || day === 6
      }

      if (this.isWeekend && customWeekEnd.includes(date)) {
        const weekOne = ['3', '4', '5', '6', '7', '8']
        if (weekOne.includes(date)) {
          this.messageTopup = '3 - 8 September 2024'
        } else {
          this.messageTopup = '12 - 16 September 2024'
        }
      } else if (this.isWeekend) {
        const startDate = moment().startOf('isoWeek').add(3, 'days')
        const endDate = moment(startDate).add(2, 'days')

        const formattedStartDate = startDate.format('D')
        const formattedEndDate = endDate.format('D MMMM YYYY')

        this.messageTopup = `${formattedStartDate} - ${formattedEndDate}`
      } else {
        this.messageTopup = 'tidak ada biaya admin'
      }
    },
    formatDate(d) {
      return moment(d).format('D MMM YYYY')
    },
    formatNumber(n) {
      return n.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async showModal() {
      this.resetModal()
      if (this.rekTujuanOptions.length === 0) {
        this.$bvModal.show('modal-notif-rekTujuanBlmAda')
        this.changeAttr()
      } else {
        this.loadingLoadDataWithdraw = true
        await this.loadBank()
        this.isCheckSaldo = false
        await this.getMaxWithdraw()
        this.$bvModal.show('modal-keuangan')
        this.changeAttr()
      }
    },
    async changeAttr() {
      const element = document.getElementsByTagName('body')[0].className

      await (element === 'modal-open')
      document.querySelectorAll('div.modal-content')[0].removeAttribute('tabindex')
    },
    closeModal() {
      this.$bvModal.hide('modalTopUp')
      this.$bvModal.hide('modal-keuangan')
    },
    resetModal() {
      this.stepNow = 0
      this.modalTitle = 'Penarikan Saldo'
      this.nominal = ''
      this.nominalState = null
      this.pin = ''
      this.pinState = null
      this.selectedRekTujuan = null
    },
    modalBack() {
      const nominalBefore = this.nominal
      const rekTujuanBefore = this.selectedRekTujuan
      this.resetModal()
      this.nominal = nominalBefore
      this.selectedRekTujuan = rekTujuanBefore
    },
    checkVerifyPIN() {
      this.$http_new.get('/auth/api/v1/user/secured/verify-pin/attempt-left')
        .then(response => {
          const { data } = response.data
          if (data.attempt_left === 0) {
            this.$bvModal.show('max-chance-PIN')
          } else {
            this.$bvModal.show('modal-verify-pin')
          }
          this.loading = false
        }).catch(() => {
          this.loading = false
          this.$toast_error({ message: 'Gagal cek PIN, silahkan refresh page' })
        })
    },
    successVerifyPIN(value) {
      this.handleSubmit(2)
    },
    backToWithdrawalModal() {
      this.$bvModal.hide('modal-verify-pin')
      this.$bvModal.show('modal-keuangan')
    },
    async handleSubmit(step) {
      const disabledWithdrawal = parseInt(this.nominal.replace(/[^0-9,-]+/g, ''), 10) < 10000

      switch (step) {
        case 1:
          if (!disabledWithdrawal) {
            this.$bvModal.hide('modal-keuangan')
            this.checkVerifyPIN()
          }
          break
        case 2:
          this.loadingConfirmationPin = true
          this.isDisableSubmitWithdraw = true
          try {
            const responseReq = this.$store.dispatch('saldo/withdrawalRequest')
            responseReq.then(val => {
              const { data } = val
              this.resTarikSaldo = data.data
              this.$nextTick(() => {
                this.stepNow = 2
                this.modalTitle = null
                this.status = data.status
              })
              this.$store.dispatch('saldo/getWithdrawalRequest')
              this.loadingConfirmationPin = false
              this.isDisableSubmitWithdraw = false
            }).catch(e => {
              if (e.response.status === 400) {
                this.$swal({
                  title:
                    '<span class="font-weight-bold h4">Penarikan Saldo Gagal</span>',
                  text: 'Maaf, kamu tidak bisa melakukan penarikan saldo dikarenakan kamu masih memiliki antrian penarikan yang belum disetujui.',
                  imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
                  showCloseButton: false,
                  focusConfirm: true,
                  confirmButtonText: 'Lihat riwayat penarikan',
                  customClass: {
                    confirmButton: 'btn bg-orange2 btn-primary rounded-lg',
                    popup: 'mr-2 ml-1',
                  },
                  buttonsStyling: false,
                }).then(res => {
                  if (res.isConfirmed) {
                    this.$router.push('/keuangan/saldo')
                  }
                })
              }
              this.loadingConfirmationPin = false
              this.isDisableSubmitWithdraw = false
            })

            this.visibilityPin = 'number'
          } catch (e) {
            this.$swal({
              title:
                '<span class="font-weight-bold h4">Penarikan Saldo Gagal</span>',
              text: e.message,
              imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
              showCloseButton: false,
              focusConfirm: true,
              confirmButtonText: 'Oke',
              customClass: {
                confirmButton: 'btn bg-orange2 btn-primary rounded-lg',
                popup: 'mr-2 ml-1',
              },
              buttonsStyling: false,
            })
            this.loadingConfirmationPin = false
            this.isDisableSubmitWithdraw = false
          }
          break
        default:
          break
      }
    },
    formatCurrency(blur, el) {
      const input = document.getElementById(el)
      let inputVal = input.value

      if (inputVal === '' || inputVal === 'Rp' || inputVal === 'Rp ') {
        return
      }

      const originalLen = inputVal.length
      let caretPos = input.selectionStart

      if (inputVal.indexOf(',') >= 0) {
        const decimalPos = inputVal.indexOf(',')
        let leftSide = inputVal.substring(0, decimalPos)
        let rightSide = inputVal.substring(decimalPos)

        leftSide = this.formatNumber(leftSide)
        rightSide = this.formatNumber(rightSide)

        if (blur) {
          rightSide += '00'
        }
        rightSide = rightSide.substring(0, 2)
        inputVal = 'Rp ' + leftSide + ',' + rightSide // eslint-disable-line
      } else {
        inputVal = this.formatNumber(inputVal)
        inputVal = 'Rp ' + inputVal // eslint-disable-line

        if (blur) {
          inputVal += ',00'
        }
      }

      input.value = inputVal

      const updatedLen = inputVal.length
      caretPos = updatedLen - originalLen + caretPos
      input.setSelectionRange(caretPos, caretPos)
    },
    stopCarret() {
      const obj = document.getElementById('input2')
      if (obj.value.length > 5) {
        this.setCaretPosition(obj, 0)
      }
    },
    setCaretPosition(elem, caretPos) {
      if (elem != null) {
        if (elem.createTextRange) {
          const range = elem.createTextRange()
          range.move('character', caretPos)
          range.select()
        } else if (elem.selectionStart) {
          elem.focus()
          elem.setSelectionRange(caretPos, caretPos)
        } else {
          elem.focus()
        }
      }
    },
    alertFail() {
      this.$swal({
        title:
          '<span class="font-weight-bold h4">Maaf sedang ada gangguan,<br>coba lagi nanti</span>',
        imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
        showCloseButton: false,
        focusConfirm: true,
        confirmButtonText: 'Oke',
        customClass: {
          confirmButton: 'btn bg-orange2 btn-primary rounded-lg',
          popup: 'mr-2 ml-1',
        },
        buttonsStyling: false,
      })
    },
    toggleVisibilityPin() {
      this.isVisibility = !this.isVisibility
    },
    onChange(v) {
      this.pin = v
    },
    onComplete(v) {
      this.pin = v
    },
    async setRekening(data) {
      const find = await this.bankItems.find(item => item.bank_account_id === data)
      this.rekeningDisplay = find
    },
    loadBank() {
      this.$http_komship.get('v1/bank-account').then(response => {
        const { data } = response.data
        this.bankItems = data
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Gagal',
            icon: 'AlertCircleIcon',
            text: 'Gagal load data, silahkan refresh halaman!',
            variant: 'danger',
          },
        }, 2000)
      })
    },
    checkWithdraw: _.debounce(async function () {
      if (Number(this.nominal.replace(/[^0-9,-]+/g, '')) < 10000) {
        this.minWithdraw = true
        this.isCheckSaldo = false
        this.isMaxWithdraw = false
      } else {
        this.loadingLoadDataWithdraw = true
        this.isMaxWithdraw = false
        this.minWithdraw = false
        await this.$http_komship.get(`/v1/partner/withdrawal/check-possible-withdraw?withdrawal_request_nominal=${this.nominal.replace(/[^0-9,-]+/g, '') === '' ? 0 : this.nominal.replace(/[^0-9,-]+/g, '')}`)
          .then(response => {
            this.maxWithdraw = this.formatPrice(response.data.data.maximum_withdraw_nominal)
            this.remainingSaldo = this.formatPrice(response.data.data.balance - Number(this.nominal.replace(/[^0-9,-]+/g, '')))
            this.idealBalance = this.formatPrice(response.data.data.ideal_balance)
            this.potencyIncome = this.formatPrice(response.data.data.potency_income)
            this.potencyRetur = this.formatPrice(response.data.data.potency_retur)
            this.withdrawPossibilites = response.data.data.withdraw_possibilites
            if (response.data.data.ideal_balance !== 0) {
              if (Number(this.nominal.replace(/[^0-9,-]+/g, '')) > response.data.data.maximum_withdraw_nominal && Number(this.nominal.replace(/[^0-9,-]+/g, '')) < response.data.data.balance) {
                this.isCheckSaldo = true
                this.isMaxWithdraw = false
                this.minWithdraw = false
              }
            }
            if (Number(this.nominal.replace(/[^0-9,-]+/g, '')) > response.data.data.balance) {
              this.isMaxWithdraw = true
              this.minWithdraw = false
              this.isCheckSaldo = false
            }
            this.loadingLoadDataWithdraw = false
          }).catch(err => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err,
                variant: 'danger',
              },
            }, 2000)
            this.loadingLoadDataWithdraw = false
          })
      }
    }, 1000),
    validateWithdraw() {
      let result = false
      if (!this.selectedRekTujuan) {
        result = true
      }
      if (this.bankItems === []) {
        result = true
      }
      if (this.withdrawPossibilites === 0) {
        result = true
      }
      return result
    },
    formatPrice(value) {
      const val = value
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    async getMaxWithdraw() {
      this.loadingLoadDataWithdraw = true
      await this.$http_komship.get(`/v1/partner/withdrawal/check-possible-withdraw?withdrawal_request_nominal=${this.nominal.replace(/[^0-9,-]+/g, '') === '' ? 0 : this.nominal.replace(/[^0-9,-]+/g, '')}`)
        .then(response => {
          this.maxValueWithdraw = response.data.data.maximum_withdraw_nominal
          this.loadingLoadDataWithdraw = false
        }).catch(err => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, 2000)
          this.loadingLoadDataWithdraw = false
        })
    },
    async handleTopup() {
      try {
        const response = await this.$store.dispatch('saldo/checkActiveBill')
        const { data } = response
        if (data.data.have_active_bill) {
          this.dataBillExist = data.data
          this.$bvModal.show('modalPaymentExist')
        } else {
          this.showTopUpModal()
        }
        if (!data.status) throw data
      } catch (e) {
        this.$swal({
          title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
          text: e.message,
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
          confirmButtonText: 'Oke',
          confirmButtonClass: 'btn btn-primary',
        })
      }
    },
    formatToNumber(value) {
      // eslint-disable-next-line radix
      const result = parseInt(value.replace(/[^0-9]/g, ''))
      return result
    },
    toastFailed(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Failed',
          icon: 'AlertCircleIcon',
          text,
          variant: 'danger',
        },
      }, 2000)
    },
    toastSuccess(text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Success',
          icon: 'SuccessIcon',
          text,
          variant: 'success',
        },
      }, 1000)
    },
    async continuePayment() {
      if (!this.dataBillExist.invoice_xendit_url) {
        const {
          qr_amount,
          qr_expire_date,
          qr_xendit_qrstring,
          qr_xendit_id,
        } = this.dataBillExist

        this.qrBillDetail = {
          amount: qr_amount,
          expire: qr_expire_date,
          qrString: qr_xendit_qrstring,
          qrId: qr_xendit_id,
        }

        this.$bvModal.show('scanQris')
      } else {
        this.$bvModal.hide('modalPaymentExist')
        try {
          window.open(this.dataBillExist.invoice_xendit_url, '_blank').focus()
        } catch (e) {
          this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
        }
        this.$refs.modalTopUp.hide()
        this.loadingTopUp = false
        this.$swal({
          html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
          // eslint-disable-next-line global-require
          imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
          confirmButtonText: 'Cek Rincian Saldo',
          confirmButtonClass: 'btn btn-primary',
        }).then(then => {
          if (then.isConfirmed) {
            this.$router.push('/keuangan/saldo/detail')
          }
        })
      }
    },
    async cancelPayment(qr) {
      try {
        const response = await !qr && this.dataBillExist.invoice_xendit_id ? this.$store.dispatch('saldo/cancelActiveBill', this.dataBillExist.invoice_xendit_id) : this.$store.dispatch('saldo/cancelQrBill', qr || this.dataBillExist.qr_xendit_id)
        const { data } = await response
        if (data.code === 200) {
          this.toastSuccess('Berhasil membatalkan pembayaran')

          this.$bvModal.hide('modalPaymentExist')
          this.$bvModal.hide('scanQris')
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
        }
        if (!data.status) throw data
      } catch ({ response }) {
        if (response.status === 400 || (response.status === 404 && response.data.data.detail === 'could not find invoice by id')) {
          this.toastSuccess('Pembayaran sudah berhasil dilakukan')
          this.$bvModal.hide('modalPaymentExist')
          this.$store.dispatch('dashboard/getBalanceSummary')
        } else {
          this.$swal({
            title: '<span class="font-semibold h4">Pembatalan Pembayaran Gagal</span>',
            text: response.data.data,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      }
    },
    async methodSelected(method) {
      if (method === 'qris' && !this.isWeekend) {
        try {
          const response = await this.$store.dispatch('saldo/qrisTopup')
          const { data } = response

          if (!data.status) throw data

          if (data.data) {
            const {
              amount,
              expires_at,
              qr_string,
              id,
            } = data.data

            this.qrBillDetail = {
              amount,
              expire: expires_at,
              qrString: qr_string,
              qrId: id,
            }

            this.$bvModal.hide('paymentMethod')
            this.$bvModal.show('scanQris')
          }
        } catch (e) {
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        }
      }
      if (method === 'bank') {
        this.$bvModal.hide('paymentMethod')

        const result = this.formatNumber(this.nominalTopUp)
        this.nominalTopUp = `Rp ${result}`
        this.loadingTopUp = true
        try {
          const response = await this.$store.dispatch('saldo/newTopUpSaldo', true)
          const { data } = response
          if (!data.status) throw data
          try {
            this.$refs.modalTopUp.hide()
            window.open(data.data.invoice_url, '_blank').focus()
          } catch (e) {
            this.$refs.modalTopUp.hide()
            this.loadingTopUp = false
            this.toastFailed('Pop-up Blocker diaktifkan! Harap tambahkan situs ini ke daftar pengecualian Anda.')
          }
          this.$refs.modalTopUp.hide()
          this.$bvModal.hide('paymentMethod')
          this.loadingTopUp = false
          this.$swal({
            html: 'Silahkan selesaikan pembayaran terlebih dahulu untuk memastikan proses Top Up Saldo Berhasil<br><br><b>Sudah menyelesaikan pembayaran?</b>',
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/warning.svg',
            confirmButtonText: 'Cek Rincian Saldo',
            confirmButtonClass: 'btn btn-primary',
          }).then(then => {
            if (then.isConfirmed) {
              this.$router.push('/keuangan/saldo/detail')
            }
          })
        } catch (e) {
          this.$refs.modalTopUp.hide()
          this.loadingTopUp = false
          this.$swal({
            title: '<span class="font-semibold h4">Top Up Saldo Gagal</span>',
            text: e.message,
            // eslint-disable-next-line global-require
            imageUrl: 'https://storage.googleapis.com/komerce/assets/icons/fail.svg',
            confirmButtonText: 'Oke',
            confirmButtonClass: 'btn btn-primary',
          })
        } finally {
          this.$store.commit('saldo/UPDATE_NOMINAL', '')
        }
      }
    },
    paymentSuccess(message) {
      this.toastSuccess(message)

      this.$store.dispatch('dashboard/getBalanceSummary')
      this.$bvModal.hide('scanQris')
      this.$bvModal.hide('modalPaymentExist')
      this.loadingTopUp = false
      this.$refs.modalTopUp.hide()
    },
    checkIsValid(nominalTopUp) {
      const valueString = nominalTopUp.replace(/Rp /g, '').replace(/\./g, '')
      const valueNumber = parseInt(valueString, 10)
      if (Number.isNaN(valueNumber) || valueNumber < 10000 || this.loadingTopUp) {
        return false
      // eslint-disable-next-line no-else-return
      } else {
        return true
      }
    },
  },
}
